.heading {
    color: #8c298c;
}

.login-form {
    text-align: left;
}

.navbar {
    background: #046A38;
}

.navbar-light .navbar-brand {
    color: white !important;
}

.navbar-light .navbar-text {
    color: white !important;
}

.footer {
    background: #046A38;
}

.link-span {
    color: #FDF6EC !important;
}

.student-range {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 2rem;
}

.table>tbody {
    vertical-align: middle !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
    --bs-table-accent-bg: #FDF6EC !important;
}

.coloredbar {
    background: linear-gradient(135deg, rgba(255, 0, 0, 1) 0%, rgba(255, 255, 0, 1) 50%, rgba(4, 106, 56, 1) 100%) !important;
}

.table {
    border-color: #DAB88B !important;
    box-shadow: 0 3px 15px rgb(0 0 0 / 20%);
}

.table>:not(:first-child) {
    border-top: 1px solid #DAB88B !important;
}

th {
    color: #046A38;
}

.table>thead {
    vertical-align: middle !important;
}

.range-numbers {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0.5rem 2rem 0 2rem;
}

.save-form {
    display: flex;
    align-items: end;
    justify-content: space-evenly;
}

.logout-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

/* MODAL */

.pure-modal .panel-footer {
    padding: 15px;
    background: #8c298c;
}

.pure-modal .panel-title {
    padding: 15px;
}

.pure-modal-backdrop .panel {
    border-radius: 15px;
    overflow: overlay;
}

.pure-modal .panel-heading {
    background: #8c298c;
}

.pure-modal .panel-body {
    background-color: #FDF6EC;
}

.pure-modal-backdrop .pure-modal {
    width: 450px;
}

.pure-modal .panel-title {
    color: white;
}

/* BUTTONS */

.orange-button {
    padding: 6px 30px !important;
    border-radius: 10px !important;
    background: #DAB88B !important;
    border: none !important;
}

.home-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* WIDTH */

.w-100 {
    width: 100% !important;
}

.w-80 {
    width: 80% !important;
}

.w-40 {
    width: 40%;
}

.w-30 {
    width: 30%;
}

.w-20 {
    width: 20%;
}

/* MARGINS */

.ml-1 {
    margin-left: 1rem;
}

.ml-2 {
    margin-left: 2rem;
}

.mr-2 {
    margin-right: 2rem;
}